<template>
  <div style="overflow: auto; height: 70vh;">
    <div class="l-main__ctn">
      <h4 class="u-typography-color md-title md-layout-item">
        {{ pageHeader }}
      </h4>
      <md-field>
        <label>Page Label</label>
        <md-input
          v-model="pageLabel"
          name="metaTitle"
          type="text"
          class="md-input"
          autocomplete="off"
          :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
          @input="checkIfInputsHaveChanged()"
        />
      </md-field>
      <div class="md-layout languages-wrapper">
        <generic-button
          v-for="language in languages"
          :key="language.code"
          :variation="language.code === selectedLanguage.code ? 'orange' : ''"
          @click="changeLanguage(language)"
        >
          {{ language.name }}
        </generic-button>
      </div>

      <template v-if="canShowForm">
        <p
          v-if="error && error.messages"
          class="md-error"
        >
          {{ error.messages }}
        </p>
        <md-field>
          <label>Page Title</label>
          <md-input
            v-model="pageTitle[selectedLanguage.code]"
            name="metaTitle"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
            @input="checkIfInputsHaveChanged()"
          />
        </md-field>
        <label class="field-label">Content</label>
        <wysiwyg-component
          v-model="content[selectedLanguage.code]"
          :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
          theme="dark"
          @input="checkIfInputsHaveChanged()"
        />
        <h4>SEO</h4>
        <md-field>
          <label>Meta Title</label>
          <md-input
            v-model="metaTitle[selectedLanguage.code]"
            name="metaTitle"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
            @input="checkIfInputsHaveChanged()"
          />
        </md-field>
        <md-field>
          <label>Meta Description</label>
          <md-input
            v-model="metaDescription[selectedLanguage.code]"
            name="metaTitle"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
            @input="checkIfInputsHaveChanged()"
          />
        </md-field>
        <md-field>
          <label>Meta Keyword</label>
          <md-input
            v-model="metaKeywords[selectedLanguage.code]"
            name="metaTitle"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
            @input="checkIfInputsHaveChanged()"
          />
        </md-field>
        <h4>Categories</h4>
        <div v-if="categories.length" class="categories-wrapper">
          <div
            v-for="category in categories"
            :key="`category-${category.id}`"
            class="checkbox-item"
          >
            <md-checkbox
              v-model="selectedCategory[category.id]"
              class="checkbox"
              :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
              @change="checkIfInputsHaveChanged()"
            />
            {{ category.label }}
          </div>
        </div>
        <div v-else class="missing-categories">
          Categories must be defined in order to access them. <br>
          To define categories go to Categories tab.
        </div>
        <md-field class="u-width-m u-mb-xs">
          <label>Page type</label>
          <md-select
            v-model="selectedPageType"
            @md-selected="checkIfInputsHaveChanged()"
          >
            <md-option
              v-for="pageType in availablePageTypes"
              :key="pageType.key"
              :value="pageType.key"
            >
              {{ pageType.label }}
            </md-option>
          </md-select>
        </md-field>
        <div
          class="checkbox-item"
        >
          <md-checkbox
            v-model="isVisibleOnWeb"
            class="checkbox"
            :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
            @change="checkIfInputsHaveChanged()"
          />
          Is Visible On Web
        </div>
        <div
          class="checkbox-item"
        >
          <md-checkbox
            v-model="isVisibleOnMobile"
            class="checkbox"
            :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
            @change="checkIfInputsHaveChanged()"
          />
          Is Visible On Mobile
        </div>
      </template>
    </div>
    <div class="u-flex-right">
      <div class="u-flex">
        <generic-button
          variation="grey"
          class="u-mr-md"
          @click="close"
        >
          Close
        </generic-button>
        <generic-button
          variation="red"
          :disabled="isSelectedCategoryEmpty || !isPageLabelValid || !canWriteHelpWebsite(getSelectedWebsiteId) || !inputsChanged"
          @click="handleUpdateOrCreate"
        >
          {{ updateOrCreateLabel }}
        </generic-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GenericButton from '@/shared/buttons/GenericButton'
import WysiwygComponent from '@/shared/wysiwyg-component/WysiwygComponent';
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";
import {websiteService} from "@/services/websites";
import {languageService} from '@/services/language';
import {jsonParse} from '@/helpers/transformers';
import {each, get, isArray, kebabCase} from 'lodash';
import {helpService} from "@/services/help";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";
import {availablePageTypes} from "@/const/help-cms";

export default {
  name: 'EditHelpPageModal',
  components: {
    WysiwygComponent,
    GenericButton
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],

  props: {
    selectedPage: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      inputsChanged: false,
      messages: {
        error: null,
        success: null,
      },
      pageLabel: '',
      categories: [],
      languages: [],
      selectedLanguage: {},
      canShowForm: false,
      pageTitle: {},
      content: {},
      metaTitle: {},
      metaDescription: {},
      metaKeywords: {},
      selectedCategory: {},
      isVisibleOnMobile: false,
      isVisibleOnWeb: false,
      selectedPageType: availablePageTypes[0].key,
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['getSelectedDomainId', 'getSelectedWebsiteId']),

    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    updateOrCreateLabel() {
      return !this.selectedPage ? 'Create' : 'Update';
    },
    isSelectedCategoryEmpty() {
      return Object.values(this.selectedCategory).every(val => !val);
    },
    isPageLabelValid() {
      return this.pageLabel.trim() !== '';
    },
    pageHeader() {
      return !this.selectedPage ? 'New Page' : 'Update Page';
    },
    availablePageTypes() {
      return availablePageTypes;
    },
  },

  destroyed() {
    this.close()
  },

  async created() {
    this.updateHelpPage = await this.historyLoggerDecorator(helpService.updateHelpPage);

    this.selectedPage ?
      this.predefinedDataFromPage() :
      this.resetData();

    this.getAllLanguagesForWebsite();
    await this.getCategories();

    this.inputsChanged = false;
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "resetError", "setGlobalError"]),
    ...mapGetters("response", ["success", "error"]),

    getAllLanguagesForWebsite() {
      this.getWebsite(this.getSelectedWebsiteId)
    },

    formatPageSlugs() {
      const slugs = {};

      each(Object.keys(this.pageTitle), pageKey => {
        slugs[pageKey] = kebabCase(this.pageTitle[pageKey]);
      })

      return slugs;
    },

    checkIfInputsHaveChanged() {
      this.inputsChanged = true;
    },

    async handleUpdateOrCreate() {
      const body = {
        categories: Object.keys(this.selectedCategory).filter(selectedCategoryId => this.selectedCategory[selectedCategoryId]),
        pageTitle: JSON.stringify(this.pageTitle),
        content: JSON.stringify(this.content),
        metaTitle: JSON.stringify(this.metaTitle),
        metaDescription: JSON.stringify(this.metaDescription),
        metaKeywords: JSON.stringify(this.metaKeywords),
        pageType: this.selectedPageType,
        isVisibleOnMobile: this.isVisibleOnMobile,
        isVisibleOnWeb: this.isVisibleOnWeb,
        label: this.pageLabel,
        pageSlug: JSON.stringify(this.formatPageSlugs())
      }

      try {
        !this.selectedPage ?
          await helpService.createHelpPage(body, this.getSelectedDomainId) :
          await this.updateHelpPage(body, this.getSelectedDomainId, this.selectedPage.helpPageId);

        const successMessage = !this.selectedPage ?
          'Page Created' :
          'Page Updated';

        this.handleSuccess({message: successMessage});
        this.close();
        this.$emit("onSuccessCreateUpdate");
      } catch (err) {
        this.setGlobalError(isArray(err.data.message) ? err.data.message[0] : err.data.message);
      }
    },

    close() {
      this.$emit("onBack");
    },

    async getWebsite(websiteId) {
      const response = await websiteService.getSingleWebsite(websiteId)
      await this.getAvailableLanguages(response.data);
    },

    async getAvailableLanguages(data) {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguageCodes = jsonParse(get(data, 'betshop.market.additionalLanguages')) || [];
      const defaultLanguageCode = get(data, 'betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguageCode, ...additionalLanguageCodes])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code));
      this.selectedLanguage = this.languages[0];
      this.canShowForm = true;
    },

    changeLanguage(language) {
      this.selectedLanguage = language;
    },

    async getCategories() {
      let response = await helpService.getHelpCategories(this.getSelectedDomainId);
      this.categories = response.data;
    },

    predefinedDataFromPage() {
      this.pageLabel = get(this.selectedPage, 'helpPage.label', '');
      this.pageTitle = get(this.selectedPage, 'helpPage.pageTitle', {});
      this.content = get(this.selectedPage, 'helpPage.content', {});
      this.metaTitle = get(this.selectedPage, 'helpPage.metaTitle', {});
      this.metaDescription = get(this.selectedPage, 'helpPage.metaDescription', {});
      this.metaKeywords = get(this.selectedPage, 'helpPage.metaKeywords', {});
      this.selectedPageType = get(this.selectedPage, 'helpPage.pageType', availablePageTypes[0].key);
      this.isVisibleOnMobile = get(this.selectedPage, 'helpPage.isVisibleOnMobile', false);
      this.isVisibleOnWeb = get(this.selectedPage, 'helpPage.isVisibleOnWeb', false);
      this.selectedCategory = get(this.selectedPage, 'helpPage.helpPageHelpCategory', false).reduce((acc, category) => {
        acc[category.helpCategoryId] = true
        return acc;
      }, {});
    },

    resetData() {
      this.pageLabel = '';
      this.categories = [];
      this.languages = [];
      this.selectedLanguage = {};
      this.canShowForm = false;
      this.pageTitle = {};
      this.content = {};
      this.metaTitle = {};
      this.metaDescription = {};
      this.metaKeywords = {};
      this.selectedCategory = {};
      this.selectedPageType = availablePageTypes[0].key
      this.isVisibleOnMobile = false;
      this.isVisibleOnWeb = false;
    }
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 20px;
  padding: 10px 0 20px;
  font-size: 16px;
  border-bottom: 1px solid black;
}

.md-checkbox {
  display: flex;
  align-items: center;
}

.u-mr-md {
  margin-right: $md !important;
}

.c-header__info {
  display: flex;
  flex-direction: column;

  .c-header__info-label {
    margin: 5px 0;
    color: $white;

    span {
      font-weight: 700;
    }
  }

  .c-header__info-label:nth-child(1) {
    margin-top: 15px;
  }
}

.categories-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.u-flex-right {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.field-label {
  margin-top: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.missing-categories {
  width: 50%;
  padding: 20px 20px;
  color: $grey;
}

.languages-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

  button {
    min-width: auto;
    padding: 5px 10px;
  }
}

</style>
